/* stylelint-disable */

$icons: (
  logo: "../../images/logo/logo.svg",
  mapmarker: "../../images/icons/mapmarker.svg",
);

/* stylelint-enable */

// Usage: @include icon(before, email, false);
// Usage custom style: @include icon(before, email) { margin-right: 20px; color: blue;}
// Usage button style: @include icon(before, email, button)
@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position == both {
    $position: "before, &:after";
  }
  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &:#{$position} {
    @if $icon {
      // A particular icon has been specified
      // background-image: url("#{map-get($icons, $icon)}");
    }

    @if $styles == button {
      content: " ";
      vertical-align: sub;

      @apply w-3 h-3 inline-block;
      @apply bg-no-repeat bg-center;

      @if $position == before {
        @apply mr-1;
      }

      @if $position == after {
        @apply ml-1;
      }
    }

    @if $styles {
      // Supportive icon styles required
      speak: none;
      font-style: normal;
      font-weight: normal;
      // Some more supportive icon styles via icomoon.io
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1;
      font-variant: normal;

      // Animation
      @apply aa-transition;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}

// Usage: 'class="inline-block align-middle icon icon--medium icon--mapmarker"'
.icon {
  @apply bg-contain bg-no-repeat bg-center;

  // Icon Sizes
  &--small {
    @apply w-2 h-2;
  }

  &--medium {
    @apply w-3 h-3;
  }

  &--big {
    @apply w-6 h-6;
  }

  &--bigger {
    @apply w-11 h-11;
  }
}

// Setup a class name for each icon
@each $name, $char in $icons {
  .icon--#{$name} {
    background-image: url($char);
  }
}

// hover in case its an <a>
a.icon {
  @apply aa-transition;

  &:hover {
    @apply scale-105;
  }
}
