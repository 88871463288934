.headerbar {
  @apply bg-black;
}

.callbutton {
  @apply hidden;

  .mobilemenu--open & {
    @apply block;
  }
}

.aa-headerHeigth {
  @screen md {
    height: 100vh;
  }

  height: 80vh;
}

.aa-customHeaderHeight {
  height: 85vh;
}

.headerLogo {
  @apply block h-10 bg-contain bg-center w-15 ml-3;

  background-image: url(../../images/logo/logo.svg);

  @screen md {
    @apply h-15 w-25 -mt-2;

    background-image: url(../../images/logo/logo-black.svg);
  }

  .mobilemenu--open & {
    @apply w-15 h-10 my-auto bg-center bg-contain;

    background-image: url(../../images/logo/logo-black.svg);
  }

  .is_stuck & {
    @apply w-15 h-10 mt-0 bg-contain;

    @screen md {
      background-image: url(../../images/logo/logo.svg);
    }
  }
}

.mobileMenu {
  .is_stuck & {
    @apply bg-white;
  }

  .mobilemenu--open & {
    @apply bg-black border-b border-gray-400;
  }
}

.header-text {
  @apply inline bg-black text-white;

  box-decoration-break: clone;
  box-shadow: 10px 0 0 #212121, -10px 0 0 #212121;
}
