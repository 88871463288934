.header__burger {
  @apply block w-3 h-3 relative overflow-hidden  md:hidden;

  &--block {
    margin-top: 3px;

    @apply block absolute w-full h-2px aa-transition bg-black rounded-small;

    .transparentheader & {
      @apply bg-white;
    }

    &:nth-child(1) {
      @apply top-0 left-0;
    }

    &:nth-child(2) {
      @apply top-1 right-0;
    }

    &:nth-child(3) {
      @apply top-2 right-0;
    }

    .mobilemenu--open & {
      @apply bg-white;

      &:nth-child(1) {
        @apply rotate-45 top-0 left-0 translate-y-1;
      }

      &:nth-child(2) {
        @apply bg-transparent top-0 right-0;
      }

      &:nth-child(3) {
        @apply -rotate-45 left-0 bottom-0 -translate-y-1 w-full;
      }
    }
  }
}
