/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
@tailwind base;

/** Import variables */
@import "common/variables";

/** Import everything from autoload */
;

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";
@import "~swiper/swiper-bundle.css";
@import "~mapbox-gl/src/css/mapbox-gl.css";

/**
 * This injects any component classes registered by Tailwind plugins.
 */

@tailwind components;

/** Import theme styles */
@import "common/global";
@import "components/icons";
@import "components/utilities";
@import "components/wp-classes";
@import "components/barba";
@import "components/menuburger";
@import "components/swiper";
@import "layouts/header";
@import "layouts/mainmenu";
@import "layouts/footer";
@import "layouts/footermenu";
@import "blocks/block-map";

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;
