.swiper-container {
  .swiper-button-prev,
  .swiper-button-next {
    left: initial;
    right: initial;
    top: initial;

    @apply relative md:flex justify-between items-center mt-0 w-7 h-full bg-primary-100 after:hidden opacity-0 hidden  transition-opacity;

    &.swiper-button-disabled span {
      @apply text-gray-200 #{!important};
    }
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      @apply opacity-100;
    }
  }
}

.swiper-pagination {
  @apply relative;

  .swiper-pagination-bullet {
    @apply mr-1 ml-0 opacity-100 bg-gray-200;

    &.swiper-pagination-bullet-active {
      @apply bg-primary-100;
    }
  }
}
