/* stylelint-disable no-descending-specificity */
#nav-primary {
  .mobilemenu--open & {
    @apply fixed inset-0;
  }

  @apply fixed top-0 w-full;

  @screen md {
    @apply static;
  }
}

.nav__headercontainer {
  @apply shadow-small bg-white;
}

#menu-mainmenu {
  @apply relative z-10 hidden list-none flex-col pl-3 pr-0 md:container md:flex md:h-auto md:flex-row md:justify-evenly md:bg-white md:pl-0;

  .is_stuck & {
    @apply md:max-w-container md:container md:w-full;
  }

  .mobilemenu--open & {
    @apply mt-11 flex h-screen bg-black pt-9;
  }

  .menu-item {
    @apply mb-3 flex h-3 -translate-x-36 items-start justify-start pl-3 transition-all md:mb-0 md:h-auto md:-translate-x-0 md:items-center md:justify-center md:py-2.5 md:pl-0;

    .is_stuck & {
      @apply md:py-1.5;
    }

    &.active {
      @apply md:bg-primary;

      a {
        @apply text-white hover:text-black;
      }
    }

    .mobilemenu--open & {
      @apply pl-0 active:bg-black;
    }

    a {
      @apply text-h3 md:text-body hover:text-primary px-2 text-white md:mb-0 md:text-black;
    }

    &.logo {
      @apply w-25 h-15 z-10 order-first mb-0 bg-no-repeat transition-all md:order-none md:-mt-3.5 md:block;

      background-image: url(../../images/logo/logo-black.svg);

      .mobilemenu--open & {
        @apply hidden;
      }

      .legals & {
        @apply md:mt-0;
      }

      .is_stuck & {
        @apply h-8 w-14 bg-contain bg-center md:mt-0;
      }

      a {
        @apply hidden;
      }
    }
  }
}

/* stylelint-enable no-descending-specificity */
