// Global styles are set in the Tailwind config using a plugin
html {
  visibility: visible;
  opacity: 1;
  scroll-behavior: smooth;

  &.noscroll {
    @apply overflow-hidden;
  }
}

body {
  @apply text-black font-display overflow-x-hidden min-h-screen;

  &.noscroll {
    @apply overflow-hidden;
  }
}

main {
  @apply relative;
}

.container {
  @apply w-full max-w-container px-0 md:px-2;
}

.content-container {
  @apply py-7.5 mx-0;
}

img,
figure {
  @apply max-w-full w-full h-auto;
}

ul.nav {
  @apply mb-0;

  li {
    @apply mb-0;
  }
}

@keyframes arrowdown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  75% {
    transform: translateY(-30px);
  }
}

.animation {
  &--arrowdown {
    animation-name: arrowdown;
    animation-delay: 1s;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
}

.aa-minusZ {
  z-index: -1;
}

.wysiwyg-block {
  p {
    @apply mb-2;
  }

  a {
    @apply hover:text-primary transition-all;
  }
}

.hyphens-auto {
  hyphens: auto;
}
