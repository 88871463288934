// Footermenu
#menu-footermenu {
  @apply pl-0 list-none;

  .menu-item {
    @apply pl-0 mb-0;

    &.facebook {
      @apply bg-no-repeat mt-8  w-3 h-3;

      background-image: url(../../images/icons/facebook.svg);

      a {
        @apply opacity-0;
      }
    }
  }
}
